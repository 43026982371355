@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "ngx-toastr/toastr";

@import "prismjs/themes/prism-okaidia.css";
@import "prismjs/plugins/line-numbers/prism-line-numbers.css";
@import "prismjs/plugins/line-highlight/prism-line-highlight.css";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
blockquote {
  border-left: 3px solid rgba(0, 0, 0, 0.21);
  padding-left: 12px;
  color: rgba(0, 0, 0, 0.54);
}
